@tailwind base;
@tailwind components;
@tailwind utilities;


.project-header{
    border-bottom: 3px solid #66FF00;
}

.project-image{
    /* object-fit: cover; */
    max-height: 100%;
}

.wavehand{
    animation:shake 1.5s infinite linear;
}

@keyframes shake{
    0%{
        transform: rotate(-10deg);
    }
    50%{
        transform: rotate(10deg);
    }
    100%{
        transform: rotate(-10deg);
    }
}